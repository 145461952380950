





















































































































































































































































































































































.seeStyle {
  display: flex;
  flex-direction: row;
}
.pageForm-btns {
  padding: 15px 0;
  text-align: center;
  .el-button {
    width: 10rem;
    span {
      min-width: 4em;
    }
  }
}
.senPDf {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}

.addtestPaper {
  .el-input--small .el-input__inner {
    height: 32px;
    line-height: 32px;
  }
}
.check-all-btn {
  font-size: 22px;
  line-height: inherit;
  color: #409eff;
  cursor: pointer;
}
.check-all-btn:hover {
  color: #66b1ff;
}
